import { IDataFetcherResponse } from "@common/utils/use-query/types";
import { generateBEAPIUrl } from "@utils/api";
import { dataFetcher } from "@utils/data";
import create from "zustand";
import {
  CreateInstanceDataType,
  IPurchase,
  PurchasesStore,
  ICreateCloudInstanceData,
  NewPurchasesStore,
} from "./types";
import { comparePurchase } from "./utils";
import { useMutation, useQuery, useQueryClient } from "@common/utils/use-query";

export const purchasesStore: NewPurchasesStore = {
  useCheckInstanceDNSAliasAvailability(
    dns_alias,
    { enabled } = { enabled: false },
  ) {
    return useQuery(
      `/account/purchases/action/instance_dns_available?dns_alias=${dns_alias}`,
      {
        enabled,
        onError(error) {
          if (error.status === 409) {
            error.message = "Looks like that DNS alias is already taken.";
          }
        },
      },
    );
  },

  useGetPurchases({ enabled } = { enabled: true }) {
    return useQuery(`/account/purchases`, {
      enabled,
      onSuccess(data) {
        if (data) {
          data = data.sort(comparePurchase);
        }
      },
    });
  },

  useGetPurchase(id, { enabled } = { enabled: true }) {
    return useQuery(`/account/purchases/${id}`, {
      enabled,
    });
  },

  useMigrateInstance(id) {
    const queryClient = useQueryClient();
    return useMutation(
      {
        url: `/account/purchases/${id}/action/initiate_instance_migration`,
        method: "PUT",
      },
      {
        onSuccess() {
          return queryClient.invalidateQueries(`/account/purchases/${id}`);
        },
      },
    );
  },

  useCancelPurchase(id) {
    const queryClient = useQueryClient();
    return useMutation(
      {
        url: `/account/purchases/${id}`,
        method: "DELETE",
      },
      {
        onSuccess() {
          queryClient.invalidateQueries("/account/purchases");
          return queryClient.invalidateQueries(`/account/purchases/${id}`);
        },
      },
    );
  },

  useSendPostCancellationSurvey(id) {
    return useMutation({
      url: `/account/purchases/${id}/cancellation_survey`,
      method: "POST",
    });
  },

  useChangeInstanceDNSAlias(id) {
    const queryClient = useQueryClient();
    return useMutation(
      {
        url: `/account/purchases/${id}/action/change_instance_dns_alias`,
        method: "PUT",
      },
      {
        onError(error) {
          if (error.status === 409 || error.status === 400) {
            error = {
              message: "Looks like that DNS alias is already taken.",
              status: 409,
            };
          }
        },
        onSuccess() {
          return queryClient.invalidateQueries(`/account/purchases/${id}`);
        },
      },
    );
  },

  useChangeInstanceDomain(id) {
    const queryClient = useQueryClient();
    return useMutation(
      {
        url: `/account/purchases/${id}/action/change_instance_custom_domain`,
        method: "PUT",
      },
      {
        onError(error) {
          if (error.status === 409 || error.status === 400) {
            error = {
              message: "Looks like that custom domain is already taken.",
              status: 409,
            };
          }
        },
        onSuccess() {
          return queryClient.invalidateQueries(`/account/purchases/${id}`);
        },
      },
    );
  },

  useChangeInstanceRegion(id) {
    const queryClient = useQueryClient();
    return useMutation(
      {
        url: `/account/purchases/${id}/action/change_region`,
        method: "PUT",
      },
      {
        onSuccess() {
          return queryClient.invalidateQueries(`/account/purchases/${id}`);
        },
      },
    );
  },

  useTryProPlan(id) {
    const queryClient = useQueryClient();
    return useMutation(
      {
        url: `/account/purchases/${id}/action/trial_up`,
        method: "PUT",
      },
      {
        onSuccess() {
          return queryClient.invalidateQueries(`/account/purchases/${id}`);
        },
      },
    );
  },

  useChangePlan(id: string) {
    const queryClient = useQueryClient();
    return useMutation(
      {
        url: `/account/purchases/${id}/action/change_plan`,
        method: "PUT",
      },
      {
        onSuccess() {
          return queryClient.invalidateQueries(`/account/purchases/${id}`);
        },
      },
    );
  },

  useGetPlanChangeEstimate(id) {
    return useMutation({
      url: `/account/purchases/${id}/action/change_plan_preview`,
      method: "PUT",
    });
  },
};

export const usePurchasesStore = create<PurchasesStore>(
  (setState, getState): PurchasesStore => ({
    loading: false,
    error: null,

    purchase: null,

    async getPurchase(id: string): Promise<IDataFetcherResponse<IPurchase>> {
      const {
        success,
        error,
        data: purchase,
      } = await dataFetcher(generateBEAPIUrl(`/account/purchases/${id}`));

      if (success) {
        setState({
          error: null,
          loading: false,
          purchase,
        });
      } else {
        setState({
          error,
          loading: false,
          purchase: null,
        });
      }

      return {
        error,
        success,
        data: purchase,
      };
    },

    newInstanceData: null,

    // intermediary method to store a new instance details
    // used during checkout
    async checkInstanceDNSAliasAvailability(
      instanceData: ICreateCloudInstanceData,
    ): Promise<IDataFetcherResponse<CreateInstanceDataType>> {
      setState({ loading: true, newInstanceData: instanceData });

      // check DNS alias availability
      const { dns_alias } = instanceData;
      let { success, error } = await dataFetcher(
        generateBEAPIUrl(
          `/account/purchases/action/instance_dns_available?dns_alias=${dns_alias}`,
        ),
      );

      if (success) {
        setState({
          error: null,
          loading: false,
          newInstanceData: instanceData,
        });
      } else {
        if (error?.status === 409) {
          error = {
            message: "Looks like that DNS alias is already taken.",
            status: 409,
          };
        }
        setState({
          error,
          loading: false,
          newInstanceData: null,
        });
      }

      return {
        error,
        success,
        data: instanceData,
      };
    },

    async createInstance(
      instanceData: CreateInstanceDataType,
    ): Promise<IDataFetcherResponse<IPurchase>> {
      setState({ loading: true, newInstanceData: instanceData });

      // check DNS availability
      // when new instance as param only
      if (instanceData["dns_alias"] && instanceData["region"]) {
        const checkInstanceDNSAliasAvailability =
          getState().checkInstanceDNSAliasAvailability;
        const {
          success: successDNSAliasAvailability,
          error: errorDNSAliasAvailability,
        } = await checkInstanceDNSAliasAvailability(
          instanceData as ICreateCloudInstanceData,
        );
        if (!successDNSAliasAvailability) {
          setState({
            error: errorDNSAliasAvailability,
            loading: false,
          });

          return {
            error: errorDNSAliasAvailability,
            success: successDNSAliasAvailability,
          };
        }
      }

      // reset the loading state
      setState({ loading: true });

      // create instance
      const { success, error, data } = await dataFetcher(
        generateBEAPIUrl("/account/purchases"),
        {
          method: "POST",
          body: instanceData,
        },
      );
      const formattedError =
        error?.message &&
        error.message.indexOf("already running") > -1 &&
        error.message.indexOf("trial") > -1
          ? { message: error.message, status: 402 }
          : error;

      if (success) {
        setState({ newInstanceData: instanceData });

        // retrieve purchase
        const getPurchase = getState().getPurchase;
        return await getPurchase(data["purchase_id"]);
      } else {
        setState({
          error: formattedError,
          loading: false,
        });
      }

      return {
        error: formattedError,
        success,
        data,
      };
    },
  }),
);
